<template>
  <div class="mt-4">
    <lineFieldsArray
      :fieldAttrRow="{}"
      :fields="fields"
      :templateContent="result"
      :value="pairArray"
      @line-field-input="checkInput(...arguments)"
      :headerTitle="headerTitle"
      :addable="addable"
      addButtTitle="Add Attribute"
      :isValid="isValid"
      :linePrefix="linePrefix"
    ></lineFieldsArray>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";

export default {
  components: { lineFieldsArray },
  data() {
    return {};
  },
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    addable: { type: Boolean, default: true },
    headerTitle: { type: Boolean },
    indexName: { default: "Index" },
    result: {},
    linePrefix: {},
    isValid: { type: Boolean },
  },
  computed: {
    fields() {
      let fields = {
        index: {
          type: "text",
          name: this.indexName,
          fieldAttrInput: { class: "required" },
          colAttrs: {
            cols: 5,
          },
        },
        value: {
          type: "text",
          inputFieldFormatter: "enteryValueFormatter",
          fieldAttrInput: { class: "required" },
          name: "Value",
        },
      };
      return fields;
    },
    pairArray: {
      get() {
        let pairArray = [];

        for (const [key, value] of Object.entries(this.value)) {
          let readOnly = false;
          if (!this.addable) {
            readOnly = true;
          }

          pairArray.push({ index: key, value: value, readOnly: readOnly });
        }
        return pairArray;
      },
      set(newValue) {
        console.log("new value", newValue);
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    checkInput(value) {
      let sentValue = {};
      let update = true;
      let caller = this;
      value.forEach(function (info) {
        if (typeof sentValue[info.index] != "undefined") {
          update = false;
          return;
        }
        caller.$set(sentValue, info.index, info.value);
      });
      if (update) {
        this.$emit("input", sentValue);
      }
    },
  },
};
</script>